<template>
  <b-overlay :show="showLoading">
    <b-row>
      <b-col cols="12">
        <b-card v-for="item in pageData">

          <b-form-group
              :label="item.title"
              label-for="blog-content"
              class="mb-2"
          >
            <b-form-textarea
                id="blog-content"
                rows="4"
                v-model="item.content"
            ></b-form-textarea>
          </b-form-group>

          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updateSection(item)"
          >
            ثبت
          </b-button>

        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BOverlay,BFormTextarea,BFormGroup
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

import {PostCreateRequest} from "@/libs/Api/Post";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {StaticPageGetByTypeRequest, StaticPageUpdateRequest} from "@/libs/Api/StaticPage";
export default {
  name: "contactUs",
  title: 'ویرایش تماس با ما - پنل ادمین مکس ',
  data(){
    return{
      pageData:null,
      createData:{
        isDeleted: false,
        postId: 0,
        userId: 0,
        content: null,
      },
      showLoading:false,
      // editorOption: {
      //   // Some Quill options...
      //   theme: "snow",
      //   modules: {
      //     imageCompress: {
      //       quality: 0.7, // default
      //       maxWidth: 500,
      //       maxHeight: 500,
      //       imageType: "image/jpeg", // default
      //       debug: true, // default
      //       suppressErrorLogging: false, // default
      //     },
      //     imageResize: {
      //       // See optional "config" below
      //     },
      //     toolbar: [
      //       ["bold", "italic", "underline", "strike"],
      //       ["blockquote", "code-block"],
      //       [{ header: 1 }, { header: 2 }],
      //       [{ list: "ordered" }, { list: "bullet" }],
      //       [{ script: "sub" }, { script: "super" }],
      //       [{ indent: "-1" }, { indent: "+1" }],
      //       [{ direction: "ltr" }],
      //       [{ size: ["small", false, "large", "huge"] }],
      //       [{ header: [1, 2, 3, 4, 5, 6, false] }],
      //       [{ font: [] }],
      //       [{ color: [] }, { background: [] }],
      //       [{ align: [] }],
      //       ["clean"],
      //       ["link", "image", "video"],
      //       ["emoji"],
      //     ],
      //   },
      // },
    }
  },
  async created(){
    await this.getData()
  },
  methods:{
    async getData(){
      let _this = this;
      _this.showLoading = true;

      let type = 1;

      let staticPageGetByTypeRequest = new StaticPageGetByTypeRequest(_this);
      staticPageGetByTypeRequest.setType(type);
      await staticPageGetByTypeRequest.fetch(function (content){
        _this.showLoading = false;
        _this.pageData = content;
      },function (error){
        _this.showLoading = false;
        console.log(error)
      })
    },
    async updateSection(param){
      let _this = this;

      _this.showLoading=true;

      let staticPageUpdateRequest = new StaticPageUpdateRequest(_this);
      staticPageUpdateRequest.setParams(param);
      await staticPageUpdateRequest.fetch(function (content){
        _this.showLoading=false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        _this.getData();
      },function (error){
        _this.showLoading=false;
        console.log(error)
      })
    },
  },
  components:{
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    quillEditor,
    BFormTextarea,
    BFormGroup
  },
}
</script>

<style scoped>

</style>